.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 50px;
  padding: 0px;
}

.btn-icon-temp {
  height: 450px;
}

.icon {
  font-size: 20px;
}
