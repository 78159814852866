.login-form {
  padding-top: 35%;
  width: 80%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
}

.signup-form {
  padding-top: 30%;
  width: 80%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
}

.form-btn {
  display: flex;
  justify-content: space-around;
}

.hello-btn {
  width: 150px;
}
