.room-list {
  width: 389px;
  height: 600px;
  margin: 0 auto;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: auto;
}

.room:hover {
  background-color: gainsboro;
}

.line {
  display: flex;
  width: 323px;
  padding: 4px;
  flex-direction: space-between;
}

.name {
  flex: 8;
  font-size: 15px;
  font-weight: bold;
  text-align: left;

  text-orientation: upright;
  white-space: nowrap;
}

.update {
  flex: 2;
  font-size: 12px;
  color: gray;
  text-align: right;
  vertical-align: bottom;

  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.message {
  flex: 8;
  font-size: 13px;
  color: gray;
  text-align: left;

  display: flex;
  flex-direction: row;
  align-items: center;

  text-orientation: upright;
  white-space: nowrap;
}

.read {
  flex: 2;
  text-align: right;
  height: 21.5px;
}
