.custom-scroll {
  /* 스크롤바 초기 상태 스타일 */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  /* 스크롤바 hover 상태 스타일 */
  &:hover {
    scrollbar-color: gray rgba(23, 107, 135, 0.1);
    transition: scrollbar-color 0.5s; /* hover 상태가 천천히 변화하도록 설정 */
  }

  /* WebKit 스크롤바 스타일 (Chrome, Safari 등) */
  &::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* 스크롤바 색상 */
    border-radius: 10px; /* 둥근 스크롤바 모양 */
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: gray; /* 스크롤바 hover 시 색상 변경 */
    transition: scrollbar-color 0.5s; /* hover 상태가 천천히 변화하도록 설정 */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* 스크롤바 트랙 색상 */
  }
}

.chat-component {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.chat-list {
  max-width: 420px;
  width: 420px;
  height: 500px;
  margin: 0 auto;
  padding: 10px 10px 8px 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: auto;
}

.info-bar {
  justify-content: flex-start;
  padding: 11px 10px 11px 10px;
}

.btn {
  border: none;
  background-color: transparent;
  padding: 5px 10px 5px 10px;
  width: 22px;
  height: 22px;
}

.icon {
  font-size: 20px;
}

.room-name {
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
  flex: 1;
  writing-mode: horizontal-tb;
  text-orientation: upright;
  white-space: nowrap;
}

.chat-line {
  display: flex;
  flex-direction: column;
}

.date-line {
  display: flex;
  justify-content: center;
}

.chat-date {
  background-color: rgb(155, 155, 155);
  color: white;
  font-size: 12px;
  border-radius: 20px;
  padding: 5px 20px;
  margin: 10px 0 10px 0;
}

.submit-btn,
.submit-btn:hover,
.submit-btn:focus {
  background-color: #06c755 !important;
}

.submit-btn[disabled],
.submit-btn[disabled]:hover,
.submit-btn[disabled]:focus {
  cursor: default !important;
  background-color: lightgray !important;
}
